import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IconCloseCircle } from "../Assets/IconCloseCircle";
import { IconDanger } from "../Assets/IconDanger";
import { IconTickCircle } from "../Assets/IconTickCircle";
import useInterval from "./Helper/useInterval";
const Styles = styled.div`
  z-index: 900;
  display: flex;
  position: fixed;
  text-align: center;
  top: 70px;
  right: calc(50vw - 150px);
  width: 18.75rem;
  height: 50px;
  background-color: var(--error-bg);
  border: 1px solid var(--error-border);
  border-radius: 8px;
  color: var(--error-border);
  padding: 0 1.1rem;
  opacity: 0;
  align-items: center;
  .icon {
    margin: 0 0 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 24px;
      stroke: var(--error-border);
      display: block;
    }
  }
  .text {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.green {
    animation: error-in-anim 0.2s ease-in-out 0s 1 forwards;
    background-color: var(--error-bg);
    border: 1px solid var(--error-border);
    color: var(--error-border);
    .icon {
      svg {
        stroke: var(--error-border);
      }
    }
  }
  &.yellow {
    animation: error-in-anim 0.3s ease-in-out 0s 1 forwards;
    background-color: var(--error-bg-yellow);
    border: 1px solid var(--error-border-yellow);
    color: var(--error-border-yellow);
    .icon {
      svg {
        stroke: var(--error-border-yellow);
      }
    }
  }
  &.red {
    animation: error-in-anim 0.3s ease-in-out 0s 1 forwards;
    background-color: var(--error-bg-red);
    border: 1px solid var(--error-border-red);
    color: var(--error-border-red);
    .icon {
      svg {
        stroke: var(--error-border-red);
      }
    }
  }
  &.exit {
    opacity: 1;
    animation: error-out-anim 0.3s ease-in-out 0s 1 forwards reverse;
  }

  @keyframes error-in-anim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes error-out-anim {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 1445px) {
  }
`;

export const ModalError = ({ errors, updateError }) => {
  const [text, setText] = useState("");
  const [type, setType] = useState("confirm");
  const [time, setTime] = useState(0);
  const [isPaused, setPaused] = useState(false);

  const [exit, setExit] = useState(false);

  const intervalRef = useInterval(
    () => {
      if (time > 0) {
        if (time === 2) {
          // go out
          setExit(true);
        }
        setTime(time - 1);
      } else {
        setPaused(true);
        updateError({});
        window.clearInterval(intervalRef.current);
      }
    },
    isPaused ? null : 1000
  );

  useEffect(() => {
    if (errors) {
      setText(errors.text);
      setTime(errors.time + 2);
      setType(errors.type);
      setExit(false);
      setPaused(false);
    }
  }, [errors]);

  function getClasses() {
    switch (type) {
      case "confirm":
        return "green";
      case "warning":
        return "yellow";
      case "error":
        return "red";
      default:
        return "";
    }
  }
  function getIcons() {
    switch (type) {
      case "confirm":
        return <IconTickCircle />;
      case "warning":
        return <IconDanger />;
      case "error":
        return <IconCloseCircle />;
      default:
        return "";
    }
  }

  if (time > 0) {
    return (
      <Styles className={getClasses() + (exit ? " exit" : "")}>
        <div className="icon">{getIcons()}</div>
        <div className="text">{text}</div>
      </Styles>
    );
  } else {
    return "";
  }
};
