import { useEffect } from 'react';
import { useLocation } from 'react-router'

function GoToTop() {
    const history = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        return () => {
        }
    }, [history]);

    return (null);
}

export default GoToTop;