export const IconDanger = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9.5V14.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.91H5.93999C2.46999 21.91 1.01999 19.43 2.69999 16.4L5.81999 10.78L8.75999 5.50003C10.54 2.29003 13.46 2.29003 15.24 5.50003L18.18 10.79L21.3 16.41C22.98 19.44 21.52 21.92 18.06 21.92H12V21.91Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9945 17.5H12.0035"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
